<template>
  <div class="ng-content-wrap">
    <div class="ng-block mt-5">
      <div class="row justify-content-center mt-5">
        <div class="col-md-4">
          <div class="mb-4 text-center justify-content-center">
            <router-link to="/auth">
              <img
                src="/img/favicon.png"
                class="login-logo"
                style="width:80px"
              />
            </router-link>
          </div>
          <div class="ng-block-head">
            <div class="ng-block-head-content text-center">
              <h3 class="ng-block-title">Change Password</h3>
            </div>
            <div
              class="alert alert-success text-success mb-2"
              v-if="is_success"
            >
              <p>{{ successMessage }}.</p>
            </div>
          </div>

          <div class="card card-bordered">
            <div class="card-header text-center" v-if="status == 'loading'">
              <div class="spinner spinner-grow"></div>
              <h6 class="card-title text-center">Processing...</h6>
            </div>

            <div class="card-body">
              <div class="alert alert-danger text-danger mb-2" v-if="has_error">
                <p>{{ errorMessage }}.</p>
              </div>
              <form
                autocomplete="off"
                @submit.prevent="changePassword"
                method="post"
              >
                <div class="form-group">
                  <label>
                    New Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="user.new_password"
                    required
                    placeholder="Enter password"
                  />
                </div>
                <div class="form-group">
                  <label>
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="user.confirm_password"
                    required
                    placeholder="Confirm password"
                  />
                </div>
                <div class="form-group text-center">
                  <button v-if="loading" class="disabled btn btn-primary">
                    loading
                  </button>
                  <button v-else type="submit" class="btn btn-primary ">
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref } from "vue";
import router from "../router";
import store from "../../../../common/services/store";
import http from "../../../../common/services/http";

export default defineComponent({
  setup() {
    function redirect() {
      router.push("/auth");
    }

    const user = reactive({
      confirm_password: "",
      new_password: ""
    });

    const has_error = ref(false);
    const errorMessage = ref("");
    const is_success = ref(false);
    const successMessage = ref("");

    const loading = ref(false);

    const status = computed(() => store.state.status);

    function changePassword() {
      has_error.value = false;
      is_success.value = false;
      loading.value = true;

      const token = this.$route.query.token;

      if (user.new_password !== user.confirm_password) {
        has_error.value = true;
        is_success.value = false;
        errorMessage.value = "Passwords dont match";
        loading.value = false;
      } else {
        http
          .post(`/api/auth/change-password?token=${token}`, user)
          .then(res => {
            if (res.status === 200) {
              successMessage.value = res.data.message;
              has_error.value = false;
              is_success.value = true;
              loading.value = false;
              user.new_password = "";
              user.confirm_password = "";
              redirect();
            }
          })
          .catch(error => {
            has_error.value = true;
            is_success.value = false;
            errorMessage.value = error.response.data.message;
            loading.value = false;
            user.new_password = "";
            user.confirm_password = "";
          });
      }
    }
    return {
      redirect,
      changePassword,
      status,
      successMessage,
      is_success,
      errorMessage,
      has_error,
      user,
      loading
    };
  }
});
</script>
